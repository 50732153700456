.error__wrapper {
  text-align: center;
  min-height: 40vh;
  padding: 80px 0 20px;
  border-top: 1px solid #e6e8eb;
}

.error__wrapper h1 {
  font-size: 5em;
  margin-bottom: 0;
  color: #e0504c;
  line-height: 1;
}

.error__wrapper h2 {
  margin-top: 0;
}
