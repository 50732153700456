* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 1rem;
}

body {
  font-family: 'Poppins', Verdana, Helvetica, sans-serif;
  font-size: 17px;
  line-height: 1.5;
  color: #626262;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* FONT */

@font-face {
  font-family: 'PS-Icons';
  src: url('fonts/PS-Icons.eot?sk4fpt');
  src: url('fonts/PS-Icons.eot?sk4fpt#iefix') format('embedded-opentype'),
    url('fonts/PS-Icons.ttf') format('truetype'),
    url('fonts/PS-Icons.woff') format('woff'),
    url('fonts/PS-Icons.svg?sk4fpt#PS-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

p {
  font-family: 'Signika', Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.text-gray-600 {
  color: #718096;
}

.bg-green-500 {
  background-color: #4ca948;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-icon-white {
  background: #ffffff;
  border-radius: 10px;
  border-style: hidden;
}

.select-white {
  padding: 14px;
  border: 0px;
  color: #645b5b;
  border-radius: 10px;
}

.select-gray {
  padding: 14px;
  border: 0px;
  background-color: #f3f4f6;
  color: #645b5b;
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.text-center {
  text-align: center;
}

/* Hide input number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: #c0c0c0 !important;
}

.btn-success-custom {
  background-color: #4ca948;
  color: white;
  border-radius: 10px;
}

.btn-success-custom:hover {
  background-color: #626262;
  color: white;
}
