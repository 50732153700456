.button-img {
  cursor: pointer;
  height: 130px;
  margin-left: 0.3em !important;
  margin-right: 0.3em !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
  justify-content: center;
  margin-top: 1em !important;
}
.fuente {
  font-size: 0.9rem !important;
}
.linea {
  background-color: #4ca948 !important;
  color: #4ca948 !important;
  height: 28px !important;
  margin-bottom: 0;
  opacity: unset !important;
}
div .button-img:hover {
  background-color: #4ca948 !important;
  color: white !important;
  outline: none;
  box-shadow: none;
}
.button-img-activo {
  background-color: #4ca948 !important;
  color: white !important;
  outline: none;
  box-shadow: none;
}
#home:hover img {
  content: url('../../../images/stoplight-icon-buttons/home-white.svg');
}

#house-data:hover img {
  content: url('../../../images/stoplight-icon-buttons/people-white.svg');
}
#dimension-data:hover img {
  content: url('../../../images/stoplight-icon-buttons/money-white.svg');
}
#stoplight-data:hover img {
  content: url('../../../images/stoplight-icon-buttons/stoplight-white.svg');
}

.card-detail {
  display: block;
  margin: auto;
}
