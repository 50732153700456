.demo-box {
    background-image: url('../../../images/fondo1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 226px;
}

.demo-box .demo-title {
    color: white;
    margin-top: 50px;
    
}

.demo-box .btn {
    padding: 10px 35px;
    font-weight: 200;
    font-size: 18px;
}
