.uc__wrapper {
  min-height: 40vh;
  padding: 80px 0 20px;
  border-top: 1px solid #e6e8eb;
}

.uc__wrapper h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #4ca948;
  line-height: 1;
}
.uc__wrapper h4 {
  font-size: 1.3em;
  margin-bottom: 10px;
  /*color: black;
    font-weight: 500;*/
}
