.title-green-h4 {
  font-weight: 700;
  color: #4ca948;
}

.perspective-result-1 {
  list-style: none;
  padding: 0 !important;
}

.perspective-result-1 li {
  font-size: 22px;
  font-weight: 700;
  margin: 14px 0;
}

.perspective-result-1 li span {
  width: 70px;
  height: 70px;
  display: inline-block;
  border: 3px solid #4ca948;
  border-radius: 50%;
}

.perspective-result-1 li img {
  width: 50px;
  height: 50px;
  margin: 7px;
}
.perspective-result-1-icon {
  width: 50px;
  height: 50px;
  margin: 7px;
}

.perspective-form .form-group label {
  color: #4ca948;
  font-weight: 700;
  padding-bottom: 14px;
}

/* .perspective-form .form-group input {
  padding: 14px;
  border: 0px;
  color: silver;
  border-radius: 10px;
} */

/* .perspective-form .form-group select {
  padding: 14px;
  border: 0px;
  color: silver;
  border-radius: 10px;
} */

.no-data {
  color: #e0504c;
}
