.dropdown-menu:li {
  text-align: left;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}

.navbar {
  min-height: 50px;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.navbar .container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.h-22 {
  height: 90px;
}
.nav-item {
  margin-left: 5px;
  margin-right: 5px;
}
.nav-item a {
  color: #718096;
  font-size: 0.875rem;
}

.dropdown-menu {
  background-color: #4ca948;
  padding: 0px;
}
.dropdown-item {
  color: white !important;
  padding: 15px;
}

.dropdown-menu a:hover {
  background-color: #3f973c;
}
.dropdown-toggle::after {
  color: rgb(200, 200, 201);
  font-size: 1.3rem;
  vertical-align: middle;
  margin-left: 15px;
}

.border-gray-300 {
  border-color: #e2e8f0;
  border-left: 1px solid #e2e8f0;
}

.navbar-light .navbar-toggler {
  color: transparent !important;
  border-color: transparent !important;
}

.navbar-dark .navbar-toggler {
  color: transparent !important;
  border-color: transparent !important;
}

@media only screen and (max-width: 600px) {
  .navbar-dark .navbar-brand {
    font-size: medium;
  }
  .navbar .px-5 {
    padding-right: 0% !important;
    padding-left: 5% !important;
  }
}
