.fixedPopUp {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  z-index: 10;
  height: 226px;
  /*width: 409px;*/
  background-color: #409737;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.12);
}
/* @keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
} */
.imgContainer {
  margin-left: 3em !important;
  margin-top: -25px;
}
.popupImage {
  height: 250px;
}

.otherTextPopContainer {
  width: 191px;
  height: 175px;
  /*display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;*/
  margin-left: 20px;
  margin-right: 0.5em !important;
}
.titlePop {
  width: 191px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 1.75rem !important;
  font-weight: bold;
  letter-spacing: 0.92px;
  line-height: 29px;
}
.subtitlePop {
  color: #50aa47;
  font-family: Poppins;
  font-size: 11.2px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 19px;
}
.buttontitlePop {
  width: 191px;
  color: #ffffff;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.58px;
  line-height: 29px;
}

.popContainerStuff {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  height: 44.8px;
  width: 158.8px;
  background-color: #ffffff;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.05);
}
.closeIconPop {
  text-decoration: none;
  position: absolute;
  top: -5%;
  right: -9%;
  cursor: pointer;
}

.badge-active {
  background: #4ca948;
}

.badge:hover {
  background: gray;
}
.no-padding {
  padding: 0px !important;
}
@media (max-width: 375px) {
  .titlePop {
    font-size: 1.2em !important;
  }
}
