
iframe {
   margin: 0 auto !important;
   overflow-y: scroll;
}
@media (max-width: 1399px) {
   iframe {
     width: 100% !important;
     
    
   }
 }

