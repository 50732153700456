/* FOOTER */

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.line {
  height: 1px;
  border-top: 1px solid #d2d5db;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
}

.footer__follow {
  text-align: center;
  margin-top: 70px;
}

.footer__follow a {
  display: inline-block;
}

.footer__icon {
  width: 60px;
  height: 60px;
  margin: 10px 15px 0;
  transition: all 0.1s ease;
}

.footer__icon:hover {
  transform: scale(1.05);
}

.fbIcon-border {
  border: 1px solid #4161a1;
  border-radius: 50%;
}

.twIcon-border {
  border: 1px solid #20aaf3;
  border-radius: 50%;
}

.ytIcon-border {
  border: 1px solid #ff0000;
  border-radius: 50%;
}

.mdIcon-border {
  border: 1px solid #272727;
  border-radius: 50%;
}

.inIcon-border {
  border: 1px solid #0080bd;
  border-radius: 50%;
}

.footer {
  font-family: "Signika", Helvetica, Arial, sans-serif;
  font-weight: 300;
}

.footer__content {
  display: flex;
  flex-wrap: wrap;
}

.footer__contacts {
  flex-grow: 4;
  text-align: right;
}

.footer__hubs,
.footer__links {
  flex-grow: 2;
}

.footer__title {
  color: #4a4a4a;
  opacity: 0.5;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.footer__link {
  display: block;
  font-size: 1rem;
  color: #50aa47;
  padding-bottom: 10px;
}

.footer__link:hover {
  color: #50aa47;
}

.footer__paragraph {
  line-height: 24px;
  font-size: 0.9rem;
  margin-bottom: 24px;
  margin-top: 0;
}

.footer__copyright {
  color: #a9a9aa;
  font-size: 0.75rem;
  float: left;
}

.footer__bragging {
  float: right;
  color: #a9a9aa;
  font-size: 0.75rem;
}

.footer__bragging a {
  text-decoration: none;
  margin-left: 0.5em;
  font-weight: bold;
  color: #828287;
}

.fpLogo {
  width: 106px;
  margin-bottom: 55px;
}

.icon-mail,
.icon-phone {
  text-decoration: none;
  color: #50aa47;
  font-size: 1.5rem;
  vertical-align: middle;
  display: inline-block;
}

.footer__paragraph > a {
  text-decoration: none;
}

.footer__phone {
  font-size: 1.1rem;
  vertical-align: middle;
  font-weight: 300;
  /* line-height: 2; */
}

@media screen and (max-width: 700px) {
  .footer__contacts {
    margin-top: 50px;
    text-align: center;
    width: 100%;
  }

  .footer__bragging,
  .footer__copyright {
    float: none;
    text-align: center;
  }

  .footer__icon {
    width: 50px;
    height: 50px;
    margin: 10px 10px 0;
  }

  .footer__line {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 470px) {
  .footer__links:nth-child(4) {
    margin-top: 50px;
    width: 50%;
  }

  .footer__contacts {
    text-align: left;
    width: 50%;
  }
}

.bubble {
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
}

.bubble-yellow {
  left: 0.1%;
  top: 250px;
  width: 40px;
  height: 40px;
  background: #f0cb17;
}

.bubble-green {
  left: 1%;
  top: 50px;
  width: 30px;
  height: 30px;
  background: #4ca948;
}

.bubble-red {
  left: 5%;
  top: 150px;
  width: 20px;
  height: 20px;
  background: #e1504d;
}

.bubble-yellow-1 {
  right: -10px;
  top: 40px;
  width: 40px;
  height: 40px;
  background: #f0cb17;
  float: right;
}

.bubble-green-1 {
  right: 0px;
  top: 220px;
  width: 30px;
  height: 30px;
  background: #4ca948;
  float: right;
}

.bubble-red-1 {
  right: 12px;
  top: 120px;
  width: 20px;
  height: 20px;
  background: #e1504d;
  float: right;
}
